import React from 'react'
import ProductsList from '../components/products-list'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const App = ({data}) => {
  return(
    <Layout>
    <ProductsList data={data} />
    </Layout>
  )
}

export default App


export const query = graphql`
query ProductsListQuery {
  products: allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___product_name]}, filter: {frontmatter: {configurator_id: {ne: ""}}, fileAbsolutePath: {regex: "/data/products/"}}) {
    edges {
      node {
        frontmatter {
          product_name
          configurator_id
          qc_status
          product_category
        }
      }
    }
  }
  productConfigs : allFile(filter: {sourceInstanceName: {eq: "product-configurations"}}) {
    edges {
      node {
        id
        childMarkdownRemark {
          frontmatter {
            use_updated_viewer
            configurator_id
          }
        }
      }
    }
  }
}
`

import React, { Component } from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'
import { DataGrid } from '@mui/x-data-grid'
import NewReleasesIcon from '@mui/icons-material/NewReleases'

class ProductsList extends Component {
  constructor(props) {
    super(props)
    let tableData = this.props.data.products.edges.map((item, i)=>{
      let config = this.props.data.productConfigs.edges.find(configItem=>{
        return configItem.node?.childMarkdownRemark?.frontmatter?.configurator_id === item.node.frontmatter.configurator_id
      })
      return {
        productName: item.node.frontmatter.product_name,
        qcStatus: item.node.frontmatter.qc_status ? item.node.frontmatter.qc_status : 'EDITING',
        category: item.node.frontmatter.product_category,
        configuratorId: item.node.frontmatter.configurator_id,
        updatedViewer: config?.node.childMarkdownRemark.frontmatter.use_updated_viewer
      }
    })
    this.state = {
      pageError: null,
      loading: true,
      surfaces: [],
      tableData: tableData,
      columns: [
        {
          field: 'qcLink', headerName: 'QC', width: 50,
          renderCell: (params) => (
            <strong>
              <Link
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                to={'/qc1/'+params.row.configuratorId}
              >
                QC
              </Link>
            </strong>
          )
        },
        {
          field: 'viewLink', headerName: 'View', width: 75,
          renderCell: (params) => (
            <strong>
              <Link
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                to={'/view/'+params.row.configuratorId}
              >
                VIEW
              </Link>
            </strong>
          )
        },
        {
          field: 'sayduckLink', headerName: 'View', width: 75,
          renderCell: (params) => (
            <strong>
              <a
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                href={'https://preview.sayduck.com/'+params.row.configuratorId+(params.row.updatedViewer? '?mode=configurator' : '?legacy&mode=configurator')}
                target="_blank"
                rel="noreferrer"
              >
                SDUCK
              </a>
            </strong>
          )
        },
        {
          field: 'productName', headerName: 'Name', width: 300
        },
        {
          field: 'category', headerName: 'Category', width: 250
        },
        {
          field: 'qcStatus', headerName: 'QC Status', width: 170
        },
        {
          field: 'newViewer', headerName: 'New Viewer', width: 75,
          renderCell: (params) => (
            <>
            {params.row.updatedViewer &&
              <NewReleasesIcon />
            }
            </>
          )
        },
      ]
    }

  }

  componentDidMount(){

  }

  render() {

    return(
      <>
        <Seo title="Products List" />
        <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{ flexGrow: 1 }}>
        <DataGrid rows={this.state.tableData} columns={this.state.columns} getRowId={(row) => row.configuratorId} />
        </div></div>
      </>
    )
  }
}

export default ProductsList
